import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  SocialSection,
  AccreditationsSection,
  HomeSliderSectionProps,
  HomeSliderSection,
  HomeIntroSectionProps,
  HomeIntroSection,
  FixedSharpImageFile,
  FileQueryResult,
  FluidSharpImageFile,
} from "@ymcansw-camping/common";
import Layout from "../components/layout";

type HomeFrontmatter = {
  readonly intro: HomeIntroSectionProps["intro"];
  readonly banners: HomeSliderSectionProps["banners"];
  readonly callToActionButtons: HomeIntroSectionProps["callToActionButtons"];
};

type Data = FileQueryResult<HomeFrontmatter> & {
  readonly introImageFile: FluidSharpImageFile;
  readonly atapLogo: FixedSharpImageFile;
  readonly acaLogo: FixedSharpImageFile;
  readonly safeguardLogo: FixedSharpImageFile;
};

export default function Home() {
  const data = useStaticQuery<Data>(
    graphql`
      {
        atapLogo: file(relativePath: { eq: "img/atap-logo.png" }) {
          childImageSharp {
            fixed(height: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        acaLogo: file(
          relativePath: { eq: "img/australian-camps-association-logo.png" }
        ) {
          childImageSharp {
            fixed(height: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        safeguardLogo: file(relativePath: { eq: "img/safeguard-logo.png" }) {
          childImageSharp {
            fixed(height: 180) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        file(relativePath: { eq: "pages/home.md" }) {
          childMarkdownRemark {
            frontmatter {
              intro {
                title
                content
              }
              banners {
                title
                summary
                buttonText
                buttonUrl
                image {
                  childImageSharp {
                    fixed(width: 2600, height: 667) {
                      src
                    }
                  }
                }
                mobileImage {
                  childImageSharp {
                    fixed(width: 800, height: 1067) {
                      src
                    }
                  }
                }
              }
              callToActionButtons {
                label
                url
              }
            }
          }
        }
      }
    `
  );
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { banners, intro, callToActionButtons },
      },
    },
    atapLogo,
    acaLogo,
    safeguardLogo,
  } = data;

  return (
    <Layout
      meta={{
        title: "Home",
        description:
          "YMCA Camp Yarramundi is one of NSW's premier outdoor education and camping venues. Located on the heritage listed Grose River in the beautiful Hawkesbury Valley.",
      }}
    >
      <HomeSliderSection banners={banners} />
      <HomeIntroSection intro={intro} callToActionButtons={callToActionButtons}>
        <iframe
          width="100%"
          height="312"
          src="https://www.youtube.com/embed/SHve3rU6w_8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </HomeIntroSection>
      <AccreditationsSection
        campName="Camp Yarramundi"
        atapLogo={atapLogo}
        acaLogo={acaLogo}
        safeguardLogo={safeguardLogo}
      />
      <SocialSection />
    </Layout>
  );
}
